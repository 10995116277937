import { render, staticRenderFns } from "./CommunicationSetup.vue?vue&type=template&id=ab135a96&scoped=true"
import script from "./CommunicationSetup.vue?vue&type=script&lang=js"
export * from "./CommunicationSetup.vue?vue&type=script&lang=js"
import style0 from "./CommunicationSetup.vue?vue&type=style&index=0&id=ab135a96&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab135a96",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ab135a96')) {
      api.createRecord('ab135a96', component.options)
    } else {
      api.reload('ab135a96', component.options)
    }
    module.hot.accept("./CommunicationSetup.vue?vue&type=template&id=ab135a96&scoped=true", function () {
      api.rerender('ab135a96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/CommunicationSetup.vue"
export default component.exports