var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "kt-portlet" }, [
      _c("div", { staticClass: "kt-portlet__head" }, [
        _c("div", { staticClass: "kt-portlet__head-label" }, [
          !_vm.isAdminLocked && !_vm.finalized
            ? _c("div", { staticStyle: { width: "72px" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "mt-3 kt-switch kt-switch--outline kt-switch--icon",
                    class: _vm.enableCommunication
                      ? "kt-switch--success"
                      : "kt-switch--danger",
                  },
                  [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.enableCommunication,
                            expression: "enableCommunication",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.enableCommunication)
                            ? _vm._i(_vm.enableCommunication, null) > -1
                            : _vm.enableCommunication,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.enableCommunication,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.enableCommunication = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.enableCommunication = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.enableCommunication = $$c
                            }
                          },
                        },
                      }),
                      _c("span"),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("h3", { staticClass: "kt-portlet__head-title" }, [
            _vm._v(" Setup Communication "),
            _c("small", [
              _vm._v(
                " " +
                  _vm._s(_vm.enableCommunication ? "Enabled" : "Disabled") +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
          _vm.shouldShowSave
            ? _c(
                "button",
                {
                  staticClass: "btn btn-clean btn-clean-primary btn-bold",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" Save ")]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "kt-portlet__body kt-portlet__body--fit" },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                center: "",
                opacity: "1",
                blur: "10px",
                show: !_vm.enableCommunication,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [_c("p")]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "kt-pricing-2" }, [
                _c("div", { staticClass: "kt-pricing-2__head" }, [
                  _c(
                    "div",
                    { staticClass: "kt-pricing-2__title kt-font-light" },
                    [_c("h1", [_vm._v("Communication Plans")])]
                  ),
                ]),
                _c("div", { staticClass: "tab-content" }, [
                  _c("div", { staticClass: "tab-pane active" }, [
                    _c("div", { staticClass: "kt-pricing-2__content" }, [
                      _c("div", { staticClass: "kt-pricing-2__container" }, [
                        _c(
                          "div",
                          { staticClass: "kt-pricing-2__items row" },
                          _vm._l(_vm.plans, function (plan) {
                            return _c(
                              "div",
                              {
                                key: plan.name,
                                staticClass: "kt-pricing-2__item",
                                staticStyle: { width: "20%" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "kt-pricing-2__visual" },
                                  [
                                    _c("div", {
                                      staticClass: "kt-pricing-2__hexagon",
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-pricing-2__icon kt-font-info",
                                      },
                                      [_c("i", { class: plan.icon })]
                                    ),
                                  ]
                                ),
                                _c(
                                  "h2",
                                  { staticClass: "kt-pricing-2__subtitle" },
                                  [_vm._v(" " + _vm._s(plan.title) + " ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "kt-pricing-2__features" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(plan.description) + " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "kt-pricing-2__btn" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-wide btn-bold btn-upper",
                                        class: [
                                          _vm.enableCommunicationValue ===
                                          plan.name
                                            ? "btn-primary"
                                            : "btn-outline-primary",
                                        ],
                                        attrs: {
                                          type: "button",
                                          disabled: !_vm.canEdit || _vm.saving,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.savePlanSettings(
                                              plan.name
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.enableCommunicationValue ===
                                                plan.name
                                                ? "Active"
                                                : "Select"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }