<template>
<div>
    <div class="kt-portlet">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <div v-if="!isAdminLocked && !finalized" style="width: 72px">
                    <span class="mt-3 kt-switch kt-switch--outline kt-switch--icon" :class="enableCommunication ? 'kt-switch--success' : 'kt-switch--danger'">
                        <label>
                            <input
                                v-model="enableCommunication"
                                type="checkbox"
                            >
                            <span />
                        </label>
                    </span>
                </div>
                <h3 class="kt-portlet__head-title">
                    Setup Communication
                    <small>
                        {{ enableCommunication ? 'Enabled' : 'Disabled' }}
                    </small>
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <button
                    v-if="shouldShowSave"
                    class="btn btn-clean btn-clean-primary btn-bold"
                    @click.stop.prevent="save"
                >
                    Save
                </button>
            </div>
        </div>
        <div class="kt-portlet__body kt-portlet__body--fit">
            <b-overlay
                :variant="'transparent'"
                center
                opacity="1"
                blur="10px"
                :show="!enableCommunication"
            >
                <template #overlay>
                    <p />
                </template>
                <div class="kt-pricing-2">
                    <div class="kt-pricing-2__head">
                        <div class="kt-pricing-2__title kt-font-light">
                            <h1>Communication Plans</h1>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active">
                            <div class="kt-pricing-2__content">
                                <div class="kt-pricing-2__container">
                                    <div class="kt-pricing-2__items row">
                                        <div
                                            v-for="plan in plans"
                                            :key="plan.name"
                                            class="kt-pricing-2__item"
                                            style="width: 20%;"
                                        >
                                            <div class="kt-pricing-2__visual">
                                                <div class="kt-pricing-2__hexagon" />
                                                <span class="kt-pricing-2__icon kt-font-info">
                                                    <i :class="plan.icon" />
                                                </span>
                                            </div>
                                            <h2 class="kt-pricing-2__subtitle">
                                                {{ plan.title }}
                                            </h2>
                                            <div class="kt-pricing-2__features">
                                                <span>
                                                    {{ plan.description }}
                                                </span>
                                            </div>
                                            <!-- <span class="kt-pricing-2__price">69</span>
                                            <span class="kt-pricing-2__label">$</span> -->
                                            <div class="kt-pricing-2__btn">
                                                <button
                                                    type="button"
                                                    class="btn btn-wide btn-bold btn-upper"
                                                    :class="[
                                                        enableCommunicationValue === plan.name ? 'btn-primary' : 'btn-outline-primary'
                                                    ]"
                                                    :disabled="!canEdit || saving"
                                                    @click.stop.prevent="savePlanSettings(plan.name)"
                                                >
                                                    {{ enableCommunicationValue === plan.name ? 'Active' : 'Select' }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import userMixins from '../store/mixins/userMixins';
import Types from '../store/Types';
import * as network from '../network';

export default {
    name: 'CommunicationSetup',
    components: {
    },
    mixins: [ userMixins],
    data() {
        return {
            saving: false,
            communicationPlan: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
        }),
        isAdminLocked() {
            const school = this.$store.state.database.schools
                .find((s) => s.schoolId == this.$store.state.user.school.schoolId);
            const { schoolYear } = this.$store.state.user.school;
            const { isSuperAdmin } = this.$store.state.user;
            const { subscriptions } = school;
            const subscription = subscriptions.find((s) => s.schoolYear == schoolYear );
            let isAdminLocked = false;
            if (subscription) {
                isAdminLocked = subscription.adminLocked && !isSuperAdmin;
            }
            return isAdminLocked;
        },
        plans() {
            return [
                // {name: 'disabled', title: 'Disabled', icon: 'la la-star-o'},
                {
                    name: 'trial',
                    title: 'Trial',
                    icon: 'la la-clock-o',
                    description: 'Limited trial plan, try before you buy'
                },
                {
                    name: 'essential',
                    title: 'Essential',
                    icon: 'la la-star-o',
                    description: 'Optimized for Small Schools of 0 - 500 students'
                },
                {
                    name: 'team',
                    title: 'Team',
                    icon: 'la la-star-half-o',
                    description: 'Optimized for Medium Schools of 501 - 700 students'
                },
                {
                    name: 'corporate',
                    title: 'Corporate',
                    icon: 'la la-star',
                    description: 'Optimized for Medium Schools of 701 - 1000 students'
                },
                {
                    name: 'enterprise',
                    title: 'Enterprise',
                    icon: 'la la-star',
                    description: 'Optimized for Large Schools of 1000+ students'
                },
            ]
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        canEdit() {
            const { isAdminLocked, finalized } = this;
            if (isAdminLocked && finalized) return this.$_userMixins_isSuperAdmin;

            const { communicationEnabled } = this.$store.state.user.subscription;
            if (finalized && !communicationEnabled) return false;
            return true;
        },
        shouldShowSave() {
            const { isAdminLocked, finalized } = this;
            if (!finalized) return false;

            const { communicationEnabled } = this.$store.state.user.subscription;
            if (!communicationEnabled) return false;
            if (isAdminLocked) return this.$_userMixins_isSuperAdmin;
            return true;
        },
        enableCommunicationValue: {
            get() {
                const { finalized } = this.$store.state.user.school;
                if (finalized) {
                    return this.communicationPlan || this.$store.state.user.subscription.communicationPlan;
                } else {
                    return this.$store.state.wizard.bypassWizardCommunication || 'disabled';
                }
            },
            set(value) {
                const { finalized } = this.$store.state.user.school;
                if (finalized) {
                    this.communicationPlan = value;
                    return;
                }

                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: this.step.routeName,
                    value,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        enableCommunication: {
            get() {
                const { bypassWizardCommunication } = this.$store.state.wizard;
                const { finalized } = this.$store.state.user.school;
                const { communicationEnabled } = this.$store.state.user.subscription;
                if (finalized || communicationEnabled) return communicationEnabled;

                if (bypassWizardCommunication == '' || bypassWizardCommunication === 'disabled') {
                    return false;
                }
                return true;
            },
            set(enableCommunication) {
                if (!this.isSetupWizard) return;
                const value = enableCommunication ? 'trial' : 'disabled';

                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    // routeName: 'setup-communication',
                    routeName: this.step.routeName,
                    value,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        isSetupWizard() {
            return this.$route.name == "SetupWizardRoute";
        },
        routes() {
            return this.$store.state.wizard.steps;
        },
        step() {
            return this.routes.find((r) => r.routeName == 'setup-communication');
        },
    },
    mounted() {
        const { isSetupWizard, isAdminLocked, enableCommunication, finalized } = this;
        if (isSetupWizard && isAdminLocked && !enableCommunication) {
            return this.$router.push({ name: 'SetupWizard' });
        }

        if (finalized) this.communicationPlan = this.$store.state.user.subscription.communicationPlan;

    },
    methods: {
        cancelConfirm() {
            this.confirmVisible = false;
        },
        savePlanSettings(plan) {
            this.enableCommunicationValue = plan;
        },
        save() {
            const v = this;
            if (v.saving) return;
            v.saving = true;


            const { user, school, isAdminLocked, finalized } = v;
            const { subscription } = user;
            const { schoolId, schoolTermId } = school;
            if (!finalized || (isAdminLocked && !v.$_userMixins_isSuperAdmin)) {
                v.saving = false;
                return;
            }

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
                body: {
                    subscription: {
                        communicationEnabled: subscription.communicationEnabled,
                        communicationPlan: v.enableCommunicationValue,
                        anecdotalsEnabled: subscription.anecdotalsEnabled,
                        gradingEnabled: subscription.gradingEnabled,
                        reportingEnabled: subscription.reportingEnabled,
                        encryptionEnabled: subscription.encryptionEnabled,
                        reportCardsEnabled: subscription.reportCardsEnabled,
                    },
                },
            };

            network.admin.editSubscriptionModules(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                window.location.reload();
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/pages/pricing/pricing-2.scss";

.kt-pricing-2__features {
    display: block;
    color: #74788d;
    font-weight: 500;
    line-height: 1.5rem;
}
div.kt-pricing-2__features {
    height: 100px;
}
</style>
